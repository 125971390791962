import React, { useState, useEffect, useCallback, useMemo } from "react";
import ProtectedRoute from "../../components/ProtectedRoute";
import AdminLayout from "../../components/AdminLayout";
import { Link } from "react-router-dom";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import OrdersTable from "../../components/OrdersTable";
import { PuffLoader } from "react-spinners";
import LayoutHeader from "../../components/LayoutHeader";
import useOrdersStore from "../../utils/useOrdersStore";

export default function Orders() {
  const [orders, setOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const { docs, fetchOrders, loading } = useOrdersStore();

  const updateOrders = useCallback((newOrders) => {
    setOrders(newOrders);
  }, []);

  const getOrders = async () => {
    await fetchOrders();
    updateOrders(docs);
  };

  useEffect(() => {
    getOrders();
    // eslint-disable-next-line
  }, []);

  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  const filteredOrders = useMemo(
    () =>
      orders.filter((order) => {
        const query = searchQuery.toLowerCase();

        return (
          order.customer_name.toLowerCase().includes(query) ||
          (order.customer_phone_no &&
            order.customer_phone_no.toLowerCase().includes(query)) ||
          (order.customer_address &&
            order.customer_address.toLowerCase().includes(query))
        );
      }),
    [orders, searchQuery]
  );

  return (
    <ProtectedRoute>
      <AdminLayout>
        <div className="h-full overflow-y-auto">
          <LayoutHeader title={"Orders"} />
          <div className="mx-4 header">
            <div className="flex justify-between py-2 ">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                placeholder="Search by Customer's name or Phone number or Address"
                className="px-2 py-2 text-gray-600 border rounded-md w-45 sm:w-[30rem]"
              />
              <div className="btn">
                <Link
                  to="/admin/add-order"
                  className="inline-flex items-center px-4 py-2 font-semibold text-white bg-blue-900 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <MdOutlineAddShoppingCart className="w-5 h-5 mr-2" />
                  <span className="hidden md:block">Add Order</span>
                </Link>
              </div>
            </div>
          </div>

          {loading && (
            <div className="flex justify-center w-full py-8">
              <PuffLoader size={60} />
            </div>
          )}
          <div className="overflow-x-auto">
            {!loading && <OrdersTable orders={filteredOrders} />}
          </div>
        </div>
      </AdminLayout>
    </ProtectedRoute>
  );
}
