import {
  collection,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import ProtectedRoute from "../../components/ProtectedRoute";
import AdminLayout from "../../components/AdminLayout";
import { FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase/firebaseConfig";
import { toastOptions } from "../../utils/helpers";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import LayoutHeader from "../../components/LayoutHeader";

const defaultPhotoUrl =
  "https://firebasestorage.googleapis.com/v0/b/hr-jkse-app.appspot.com/o/user.png?alt=media&token=cab33bcf-4be8-43f2-bbcd-5172266c0da4";

export default function AddOrder() {
  const navigate = useNavigate();
  const storage = getStorage();

  const [showModal, setShowModal] = useState(false);
  const [upiPaymentMethod, setUpiPaymentMethod] = useState("");

  const [firmName, setFirmName] = useState("jk_solar_energy");
  const [advisorCode, setAdvisorCode] = useState("");
  const [advisorName, setAdvisorName] = useState("");

  const [customerName, setCustomerName] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerPhoneNo, setCustomerPhoneNo] = useState("");
  const [customerAlternatePhoneNo, setCustomerAlternatePhoneNo] = useState("");
  const [customerAadharNo, setCustomerAadharNo] = useState("");
  const [customerPanNo, setCustomerPanNo] = useState("");
  const [customerParentage, setCustomerParentage] = useState("");
  const [customerBankName, setCustomerBankName] = useState("");
  const [customerBankAccountNo, setCustomerBankAccountNo] = useState("");
  const [customerBankIfscCode, setCustomerBankIfscCode] = useState("");
  const [customerPhotoUrl, setCustomerPhotoUrl] = useState("");
  const [customerOccupation, setCustomerOccupation] = useState("");
  const [customerQualification, setCustomerQualification] = useState("");

  const [guarantor, setGuarantor] = useState(false);
  const [guarantorName, setGuarantorName] = useState("");
  const [guarantorPanNo, setGuarantorPanNo] = useState("");
  const [guarantorAadharNo, setGuarantorAadharNo] = useState("");
  const [guarantorAddress, setGuarantorAddress] = useState("");
  const [guarantorBankAccountNo, setGuarantorBankAccountNo] = useState("");
  const [guarantorBankName, setGuarantorBankName] = useState("");
  const [guarantorIfscCode, setGuarantorIfscCode] = useState("");
  const [guarantorParentage, setGuarantorParentage] = useState("");
  const [guarantorPhoneNo, setGuarantorPhoneNo] = useState("");
  const [guarantorPhotoUrl, setGuarantorPhotoUrl] = useState("");
  const [guarantorOccupation, setGuarantorOccupation] = useState("");
  const [guarantorQualification, setGuarantorQualification] = useState("");

  const [finance, setFinance] = useState(false);
  const [isCash, setIsCash] = useState(false);
  const [financedBy, setFinancedBy] = useState("");

  const [orderDetails, setOrderDetails] = useState("");
  const [orderCost, setOrderCost] = useState("");
  const [orderNotes, setOrderNotes] = useState("");
  const [orderDate, setOrderDate] = useState("");

  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [downpayment, setDownpayment] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [chequeNumber, setChequeNumber] = useState("");
  const [chequeDate, setChequeDate] = useState("");
  const [chequeBankName, setChequeBankName] = useState("");

  const [addBtnClicked, setAddBtnClicked] = useState(false);
  const [customerImageUploading, setCustomerImageUploading] = useState(false);
  const [guarantorImageUploading, setGuarantorImageUploading] = useState(false);

  const [performaBillNo, setPerformaBillNo] = useState("");
  const [challanNo, setChallanNo] = useState("");
  const [commission, setCommission] = useState("");

  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const handlePaymentClick = (method) => {
    setUpiPaymentMethod(method);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAddBtnClicked(true);

    const data = {
      firm_name: firmName,
      advisor_code: advisorCode === "" ? "N/A" : advisorCode,
      advisor_name: advisorName === "" ? "N/A" : advisorName,
      customer_name: customerName,
      customer_address: customerAddress,
      customer_phone_no: customerPhoneNo,
      customer_alternate_phone_no:
        customerAlternatePhoneNo === "" ? "N/A" : customerAlternatePhoneNo,
      customer_aadhar_no: customerAadharNo === "" ? "N/A" : customerAadharNo,
      customer_pan_no: customerPanNo === "" ? "N/A" : customerPanNo,
      customer_parentage: customerParentage,
      customer_bank_account_no:
        customerBankAccountNo === "" ? "N/A" : customerBankAccountNo,
      customer_bank_name: customerBankName === "" ? "N/A" : customerBankName,
      customer_bank_ifsc_code:
        customerBankIfscCode === "" ? "N/A" : customerBankIfscCode,
      customer_photo_url: customerPhotoUrl,
      customer_occupation: customerOccupation,
      customer_qualification: customerQualification,
      guarantor: guarantor,
      guarantor_name: guarantorName,
      guarantor_pan_no: guarantorPanNo,
      guarantor_aadhar_no: guarantorAadharNo,
      guarantor_address: guarantorAddress,
      guarantor_bank_account_no: guarantorBankAccountNo,
      guarantor_bank_name: guarantorBankName,
      guarantor_bank_ifsc_code: guarantorIfscCode,
      guarantor_parentage: guarantorParentage,
      guarantor_phone_no: guarantorPhoneNo,
      guarantor_photo_url: guarantorPhotoUrl,
      guarantor_occupation: guarantorOccupation,
      guarantor_qualification: guarantorQualification,
      finance: finance,
      financed_by: financedBy,
      order_details: orderDetails,
      order_date: orderDate,
      order_cost: orderCost,
      order_notes: orderNotes,
      payment_method: paymentMethod,
      downpayment: downpayment === "" ? "N/A" : downpayment,
      transaction_id: transactionId,
      chequeNumber: chequeNumber,
      chequeDate: chequeDate,
      chequeBankName: chequeBankName,
      advisor: true,
      order_status: "pending",
      customer_id: `${
        firmName === "jk_solar_energy"
          ? "JKSE"
          : firmName === "saaslynx"
          ? "SSLX"
          : "JKSS"
      }-CUS-${customerPhoneNo}`,
      performaBillNo: performaBillNo,
      challanNo: challanNo === "" ? "N/A" : challanNo,
      commission: commission === "" ? "N/A" : commission,
      created_at: serverTimestamp(),
    };

    try {
      const newDocRef = doc(collection(db, "orders"));
      await setDoc(newDocRef, data);

      // set firm
      const firmCode =
        firmName === "jk_solar_energy"
          ? "JKSE"
          : firmName === "saaslynx"
          ? "SSLX"
          : "JKSS";

      // check if customer id in present in customers collection
      const customerDocRef = doc(
        db,
        "customers",
        `${firmCode}-CUS-${customerPhoneNo}`
      );
      const customerDoc = await getDoc(customerDocRef);
      if (!customerDoc.exists()) {
        // if not present then add customer to customers collection
        const customerData = {
          customer_name: customerName,
          customer_phone_no: customerPhoneNo,
          photoUrl: customerPhotoUrl,
          firmName: firmName,
          createdAt: serverTimestamp(),
        };
        await setDoc(customerDocRef, customerData);
      }
      setAddBtnClicked(false);
      toast.success("Order added successfully!", toastOptions);
      navigate("/admin/orders");
    } catch (error) {
      setAddBtnClicked(false);
      console.error(error);
    }
  };

  const getAllEmployees = async () => {
    const employees = [];
    const employeesRef = collection(db, "employee_codes");
    const employeesSnapshot = await getDocs(employeesRef);
    employeesSnapshot.forEach((doc) => {
      employees.push({
        employee_name: doc.data().employee_name,
        employee_code: doc.data().employee_code,
      });
    });
    // Others (if employee is not applicable)
    employees.push({
      employee_name: "Others",
      employee_code: "Others",
    });
    setEmployees(employees);
  };

  useEffect(() => {
    setCustomerPhotoUrl(defaultPhotoUrl);
    setGuarantorPhotoUrl(defaultPhotoUrl);
    getAllEmployees();
  }, []);

  const handleImageUpload = async (e, type) => {
    const isCustomer = type === "customer";
    try {
      const file = e.target.files[0];
      const setLoading = isCustomer
        ? setCustomerImageUploading
        : setGuarantorImageUploading;
      const setPhotoUrl = isCustomer
        ? setCustomerPhotoUrl
        : setGuarantorPhotoUrl;
      setLoading(true);
      if (!file) {
        toast.error("Please choose a file first!", toastOptions);
        setLoading(false);
        return;
      }
      const timestamp = Date.now();
      const storageRef = ref(storage, `/orders/${timestamp}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      await uploadTask;
      const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
      setPhotoUrl(downloadUrl);
      setLoading(false);
    } catch (error) {
      const setDefaultPhotoUrl = isCustomer
        ? setCustomerPhotoUrl
        : setGuarantorPhotoUrl;
      setDefaultPhotoUrl(defaultPhotoUrl);
      console.error(error);
    }
  };

  const handleCustomerImageUpload = (e) => handleImageUpload(e, "customer");
  const handleGuarantorImageUpload = (e) => handleImageUpload(e, "guarantor");

  const handleAdvisorNameChange = (e) => {
    const inputName = e.target.value;
    setAdvisorName(inputName);

    const filtered = employees.filter((employee) =>
      employee.employee_name.toLowerCase().includes(inputName.toLowerCase())
    );
    setFilteredEmployees(filtered);
  };

  const handleEmployeeSelection = (selectedEmployee) => {
    setAdvisorName(selectedEmployee.employee_name);
    setAdvisorCode(selectedEmployee.employee_code);
    setFilteredEmployees([]); // Clear the suggestions
  };

  return (
    <ProtectedRoute>
      <AdminLayout>
        <div className="h-full mx-2 overflow-x-auto overflow-y-auto ">
          <LayoutHeader title={"Add Order"} />
          <form onSubmit={handleSubmit}>
            <div className="p-4">
              <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:flex md:flex-wrap md:-mx-4">
                <div className="w-full md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="firmName"
                  >
                    Firm Name*:
                  </label>

                  <select
                    id="position"
                    required
                    value={firmName ?? ""}
                    onChange={(e) => setFirmName(e.target.value)}
                    className="w-full px-3 border rounded-lg md:py-2 focus:outline-none focus:shadow-outline"
                  >
                    <option value="">Select Firm Name</option>
                    <option value="jk_solar_energy">JK Solar Energy</option>
                    <option value="jk_sale_and_service">
                      JK Sale and Service Agency
                    </option>
                    <option value="saaslynx">SaasLynx</option>
                  </select>
                </div>
                <div className="w-full md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="advisorCode"
                  >
                    Advisor Code:
                  </label>
                  <input
                    type="text"
                    id="advisorCode"
                    maxLength={8}
                    placeholder="Optional"
                    value={advisorCode ?? ""}
                    onChange={(e) =>
                      setAdvisorCode(e.target.value.toUpperCase())
                    }
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>

                <div className="mb-4 md:w-1/2 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="advisorName"
                  >
                    Advisor Name:
                  </label>
                  <input
                    type="text"
                    id="advisorName"
                    placeholder="Optional"
                    value={advisorName ?? ""}
                    onChange={handleAdvisorNameChange}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                  {filteredEmployees.length > 0 && (
                    <ul className=" w-auto h-[20rem] mt-2 overflow-y-scroll bg-white border border-gray-300 rounded-md">
                      {filteredEmployees.map((employee) => (
                        <li
                          key={employee.employee_code}
                          className="px-3 py-2 cursor-pointer hover:bg-gray-100"
                          onClick={() => handleEmployeeSelection(employee)}
                        >
                          {employee.employee_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="mb-4 md:w-1/2 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="orderDate"
                  >
                    Order Date*:
                  </label>
                  <input
                    type="date"
                    id="orderDate"
                    required
                    value={orderDate ?? ""}
                    onChange={(e) => setOrderDate(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-2 mb-4 md:grid-cols-2 md:flex md:flex-wrap md:-mx-4">
                <div className="w-full md:w-1/2 md:px-4">
                  <div className="mb-4">
                    <label
                      className="block mb-2 font-bold text-gray-700"
                      htmlFor="customerName"
                    >
                      Customer Name*:
                    </label>
                    <input
                      type="text"
                      id="customerName"
                      required
                      placeholder="Enter Customer Name"
                      value={customerName ?? ""}
                      onChange={(e) => setCustomerName(e.target.value)}
                      className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block mb-2 font-bold text-gray-700"
                      htmlFor="customerParentage"
                    >
                      Customer Parentage*:
                    </label>
                    <input
                      type="text"
                      id="customerParentage"
                      required
                      placeholder="Enter Customer Parentage"
                      value={customerParentage ?? ""}
                      onChange={(e) => setCustomerParentage(e.target.value)}
                      className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block mb-2 font-bold text-gray-700"
                      htmlFor="customerAddress"
                    >
                      Customer Address*:
                    </label>
                    <input
                      type="text"
                      id="customerAddress"
                      required
                      placeholder="Enter Customer Address"
                      value={customerAddress ?? ""}
                      onChange={(e) => setCustomerAddress(e.target.value)}
                      className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block mb-2 font-bold text-gray-700"
                      htmlFor="customerPhoneNo"
                    >
                      Customer Phone No*:
                    </label>
                    <input
                      type="text"
                      maxLength={10}
                      id="customerPhoneNo"
                      required
                      placeholder="Enter Customer Phone Number"
                      value={customerPhoneNo ?? ""}
                      onChange={(e) => setCustomerPhoneNo(e.target.value)}
                      className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                    />
                  </div>
                </div>
                <div className="flex items-center w-full space-x-3 md:w-1/3 md:px-4 ">
                  {customerPhotoUrl && (
                    <img
                      src={customerPhotoUrl}
                      alt="customer"
                      className="w-20 mt-4 rounded-lg md:w-40 "
                    />
                  )}
                  {!customerImageUploading ? (
                    <div className="text-center">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleCustomerImageUpload}
                        className="hidden"
                        id="customer-image-upload"
                      />
                      <label
                        htmlFor="customer-image-upload"
                        className="p-2 text-white bg-blue-900 rounded cursor-pointer md:py-2 hover:bg-blue-700"
                      >
                        Upload Image
                      </label>
                    </div>
                  ) : (
                    <FaSpinner className="mx-2 animate-spin" size={20} />
                  )}
                </div>
              </div>
              <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:flex md:flex-wrap md:-mx-4">
                <div className="mb-4 md:w-1/2 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="customerAlternatePhoneNo"
                  >
                    Customer Alternate Phone No:
                  </label>
                  <input
                    type="text"
                    maxLength={10}
                    id="customerAlternatePhoneNo"
                    placeholder="Optional"
                    value={customerAlternatePhoneNo ?? ""}
                    onChange={(e) =>
                      setCustomerAlternatePhoneNo(e.target.value)
                    }
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="w-full md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="customerQualification"
                  >
                    Customer Qualification*:
                  </label>
                  <input
                    type="text"
                    id="customerQualification"
                    placeholder="Enter Customer Qualification"
                    required
                    value={customerQualification ?? ""}
                    onChange={(e) => setCustomerQualification(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4 md:w-1/2 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="customerOccupation"
                  >
                    Customer Occupation*:
                  </label>
                  <input
                    type="text"
                    id="customerOccupation"
                    placeholder="Enter Customer Occupation"
                    required
                    value={customerOccupation ?? ""}
                    onChange={(e) => setCustomerOccupation(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>

                <div className="w-full md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="customerPanNo"
                  >
                    Customer PAN no:
                  </label>
                  <input
                    type="text"
                    id="customerPanNo"
                    placeholder="Optional"
                    value={customerPanNo ?? ""}
                    onChange={(e) => setCustomerPanNo(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4 md:w-1/2 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="customerAadharNo"
                  >
                    Customer Aadhar No:
                  </label>
                  <input
                    type="text"
                    id="customerAadharNo"
                    placeholder="Optional"
                    value={customerAadharNo ?? ""}
                    onChange={(e) => setCustomerAadharNo(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4 md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="customerBankAccountNo"
                  >
                    Customer Bank Account No:
                  </label>
                  <input
                    type="text"
                    id="customerBankAccountNo"
                    placeholder="Optional"
                    value={customerBankAccountNo ?? ""}
                    onChange={(e) => setCustomerBankAccountNo(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="w-full md:w-1/2 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="customerBankName"
                  >
                    Customer Bank Name:
                  </label>
                  <input
                    type="text"
                    id="customerBankName"
                    placeholder="Optional"
                    value={customerBankName ?? ""}
                    onChange={(e) => setCustomerBankName(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="w-full md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="customerBankIfscCode"
                  >
                    Customer Bank IFSC Code:
                  </label>
                  <input
                    type="text"
                    id="customerBankIfscCode"
                    placeholder="Optional"
                    value={customerBankIfscCode ?? ""}
                    onChange={(e) => setCustomerBankIfscCode(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
              </div>
              <div className="pt-4 mb-4">
                <label className="inline-flex items-center font-bold text-gray-700">
                  <input
                    type="checkbox"
                    className="w-5 h-5 text-blue-600 form-checkbox"
                    checked={guarantor ?? false}
                    onChange={(e) => setGuarantor(e.target.checked)}
                  />
                  <span className="ml-2">Guarantor</span>
                </label>
              </div>
              {guarantor && (
                <>
                  <div className="grid grid-cols-1 gap-2 mb-4 md:grid-cols-2 md:flex md:flex-wrap md:-mx-4">
                    <div className="w-full md:w-1/2 md:px-4">
                      <div className="mb-4">
                        <label
                          className="block mb-2 font-bold text-gray-700"
                          htmlFor="guarantorName"
                        >
                          Guarantor Name:
                        </label>
                        <input
                          type="text"
                          id="guarantorName"
                          placeholder="Enter Guarantor Name"
                          value={guarantorName ?? ""}
                          onChange={(e) => setGuarantorName(e.target.value)}
                          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                        />
                      </div>
                      <div className="mb-4 ">
                        <label
                          className="block mb-2 font-bold text-gray-700"
                          htmlFor="guarantorParentage"
                        >
                          Guarantor Parentage:
                        </label>
                        <input
                          type="text"
                          id="guarantorParentage"
                          placeholder="Enter Guarantor Parentage"
                          value={guarantorParentage ?? ""}
                          onChange={(e) =>
                            setGuarantorParentage(e.target.value)
                          }
                          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                        />
                      </div>
                    </div>
                    <div className="flex items-center w-full space-x-3 md:w-1/3 md:px-4 ">
                      {guarantorPhotoUrl && (
                        <img
                          src={guarantorPhotoUrl}
                          alt="guarantor"
                          className="w-20 mt-4 rounded-lg md:w-40 "
                        />
                      )}
                      {!guarantorImageUploading ? (
                        <div className="text-center">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleGuarantorImageUpload}
                            className="hidden"
                            id="guarantor-image-upload"
                          />
                          <label
                            htmlFor="guarantor-image-upload"
                            className="p-2 text-white bg-blue-900 rounded cursor-pointer md:py-2 hover:bg-blue-700"
                          >
                            Upload Image
                          </label>
                        </div>
                      ) : (
                        <FaSpinner className="mx-2 animate-spin" size={20} />
                      )}
                    </div>
                  </div>

                  <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:flex md:flex-wrap md:-mx-4">
                    <div className="mb-4 md:w-1/2 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="guarantorAddress"
                      >
                        Guarantor Address:
                      </label>
                      <input
                        type="text"
                        id="guarantorAddress"
                        placeholder="Enter Customer Address"
                        value={guarantorAddress ?? ""}
                        onChange={(e) => setGuarantorAddress(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4 md:w-1/2 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="guarantorPhoneNo"
                      >
                        Guarantor Phone No:
                      </label>
                      <input
                        type="text"
                        id="guarantorPhoneNo"
                        placeholder="Enter Guarantor Phone Number"
                        value={guarantorPhoneNo ?? ""}
                        onChange={(e) => setGuarantorPhoneNo(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>

                    <div className="w-full md:w-1/3 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="guarantorOccupation"
                      >
                        Guarantor Occupation:
                      </label>
                      <input
                        type="text"
                        id="guarantorOccupation"
                        placeholder="Enter Guarantor Occupation"
                        required
                        value={guarantorOccupation ?? ""}
                        onChange={(e) => setGuarantorOccupation(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4 md:w-1/2 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="guarantorAadharNo"
                      >
                        Guarantor Aadhar No:
                      </label>
                      <input
                        type="text"
                        id="guarantorAadharNo"
                        placeholder="Enter Guarantor Aadhar No"
                        value={guarantorAadharNo ?? ""}
                        onChange={(e) => setGuarantorAadharNo(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="w-full md:w-1/3 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="guarantorQualification"
                      >
                        Guarantor Qualification:
                      </label>
                      <input
                        type="text"
                        id="guarantorQualification"
                        placeholder="Enter Guarantor Qualification"
                        required
                        value={guarantorQualification ?? ""}
                        onChange={(e) =>
                          setGuarantorQualification(e.target.value)
                        }
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>

                    <div className="mb-4 md:w-1/2 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="guarantorPanNo"
                      >
                        Guarantor PAN no:
                      </label>
                      <input
                        type="text"
                        id="guarantorPanNo"
                        placeholder="Enter Guarantor PAN no"
                        value={guarantorPanNo ?? ""}
                        onChange={(e) => setGuarantorPanNo(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>

                    <div className="mb-4 md:w-1/3 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="guarantorBankAccountNo"
                      >
                        Guarantor Bank Account No:
                      </label>
                      <input
                        type="text"
                        id="guarantorBankAccountNo"
                        placeholder="Enter Guarantor Bank Account No"
                        value={guarantorBankAccountNo ?? ""}
                        onChange={(e) =>
                          setGuarantorBankAccountNo(e.target.value)
                        }
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="w-full md:w-1/2 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="guarantorBankName"
                      >
                        Guarantor Bank Name:
                      </label>
                      <input
                        type="text"
                        id="guarantorBankName"
                        placeholder="Enter Guarantor Bank Name"
                        value={guarantorBankName ?? ""}
                        onChange={(e) => setGuarantorBankName(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="w-full md:w-1/3 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="guarantorIfscCode"
                      >
                        Guarantor Bank IFSC Code:
                      </label>
                      <input
                        type="text"
                        id="guarantorIfscCode"
                        placeholder="Enter Guarantor Bank IFSC Code"
                        value={guarantorIfscCode ?? ""}
                        onChange={(e) => setGuarantorIfscCode(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="grid grid-cols-1 gap-2 md:mt-4 md:grid-cols-2 md:flex md:flex-wrap md:-mx-4">
                <div className="mt-2 md:mb-4 md:w-1/3 md:px-4">
                  <label className="inline-flex items-center font-bold text-gray-700">
                    <input
                      type="checkbox"
                      className="w-5 h-5 text-blue-600 form-checkbox"
                      checked={finance ?? false}
                      onChange={(e) => {
                        setIsCash(false);
                        setFinance(e.target.checked);
                      }}
                    />
                    <span className="ml-2">Finance</span>
                  </label>
                </div>
                {finance && (
                  <div className="mb-4 md:w-1/2">
                    <select
                      id="position"
                      value={financedBy ?? ""}
                      onChange={(e) => setFinancedBy(e.target.value)}
                      className="w-full px-3 border rounded-lg md:py-2 focus:outline-none focus:shadow-outline"
                    >
                      <option value="">Select Finance Partner</option>
                      <option value="j_and_k_bank">J&K Bank</option>
                      <option value="sbi_bank">SBI Bank</option>
                      <option value="hdfc_bank">HDFC Bank</option>
                      <option value="bajaj_finserv">Bajaj Finserv</option>
                      <option value="samsung_finance">Samsung Finance</option>
                    </select>
                  </div>
                )}
              </div>
              <div className="mt-2 md:mb-4 md:w-1/3">
                <label className="inline-flex items-center font-bold text-gray-700">
                  <input
                    type="checkbox"
                    className="w-5 h-5 text-blue-600 form-checkbox"
                    checked={isCash ?? false}
                    onChange={(e) => {
                      setFinance(false);
                      setIsCash(e.target.checked);
                    }}
                  />
                  <span className="ml-2">Cash Payment (No Finance)</span>
                </label>
              </div>
              <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:flex md:flex-wrap md:-mx-4">
                <div className="mt-4 mb-4 md:mt-0 md:w-1/2 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="orderDetails"
                  >
                    Order Details*:
                  </label>
                  <textarea
                    type="text"
                    id="orderDetails"
                    required
                    rows={4}
                    placeholder="Enter Order Details"
                    value={orderDetails ?? ""}
                    onChange={(e) => setOrderDetails(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>

                <div className="mb-4 md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="orderCost"
                  >
                    Order Cost*:
                  </label>
                  <input
                    type="number"
                    id="orderCost"
                    required
                    placeholder="Enter Order Cost"
                    value={orderCost ?? ""}
                    onChange={(e) => setOrderCost(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>

                <div className="mb-4 md:w-1/2 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="orderNotes"
                  >
                    Order Notes:
                  </label>
                  <textarea
                    type="text"
                    id="orderNotes"
                    rows={3}
                    placeholder="Enter Order Notes (Optional)"
                    value={orderNotes ?? ""}
                    onChange={(e) => setOrderNotes(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>

                <div className="flex items-center mb-4 space-x-4 md:w-1/3 md:px-4">
                  <button
                    onClick={() => handlePaymentClick("googlePay")}
                    type="button"
                    className="flex items-center justify-center gap-2 px-4 py-2 font-semibold bg-gray-200 rounded-lg hover:bg-gray-300"
                  >
                    <img
                      className="w-auto h-14"
                      src="/assets/gpay_icon.png"
                      alt="gpay"
                    />
                  </button>

                  <button
                    onClick={() => handlePaymentClick("phonePay")}
                    type="button"
                    className="flex items-center justify-center gap-2 px-4 py-2 font-semibold bg-gray-200 rounded-lg hover:bg-gray-300"
                  >
                    <img
                      className="w-auto h-14"
                      src="/assets/phone_pe_icon.png"
                      alt="phonepe"
                    />
                  </button>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:flex md:flex-wrap md:-mx-4">
                <div className="mt-4 mb-4 md:mt-0 md:w-1/2 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="paymentMethod"
                  >
                    Payment Method*:
                  </label>
                  <select
                    id="paymentMethod"
                    value={paymentMethod ?? ""}
                    required
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    className="w-full px-3 border rounded-lg md:py-2 focus:outline-none focus:shadow-outline"
                  >
                    <option value="">Select Payment Method</option>
                    <option value="cash">Cash</option>
                    <option value="cheque">Cheque</option>
                    <option value="card">Card</option>
                    <option value="upi">UPI</option>
                  </select>
                </div>

                <div className="mb-4 md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="downpayment"
                  >
                    Downpayment:
                  </label>
                  <input
                    type="number"
                    id="downpayment"
                    placeholder="Optional"
                    value={downpayment ?? ""}
                    onChange={(e) => setDownpayment(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
                {(paymentMethod === "card" || paymentMethod === "upi") && (
                  <div className="w-full md:w-1/2 md:px-4">
                    <label
                      className="block mb-2 font-bold text-gray-700"
                      htmlFor="transactionId"
                    >
                      Transaction Id:
                    </label>
                    <input
                      type="text"
                      id="transactionId"
                      placeholder="Enter Transaction Id"
                      value={transactionId ?? ""}
                      onChange={(e) => setTransactionId(e.target.value)}
                      className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                    />
                  </div>
                )}
                {paymentMethod === "cheque" && (
                  <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:flex md:flex-wrap md:-mx-1">
                    <div className="w-full md:w-1/3 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="chequeNumber"
                      >
                        Cheque Number:
                      </label>
                      <input
                        type="text"
                        id="chequeNumber"
                        placeholder="Enter Cheque Number"
                        value={chequeNumber ?? ""}
                        onChange={(e) => setChequeNumber(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4 md:w-1/2 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="chequeBankName"
                      >
                        Cheque Bank Name:
                      </label>
                      <input
                        type="text"
                        id="chequeBankName"
                        placeholder="Enter Cheque Bank Name"
                        value={chequeBankName ?? ""}
                        onChange={(e) => setChequeBankName(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>

                    <div className="mb-4 md:w-1/2 md:px-4">
                      <label
                        className="block mb-2 font-bold text-gray-700"
                        htmlFor="chequeDate"
                      >
                        Cheque Date:
                      </label>
                      <input
                        type="date"
                        id="chequeDate"
                        required
                        value={chequeDate ?? ""}
                        onChange={(e) => setChequeDate(e.target.value)}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="grid grid-cols-1 gap-2 md:mt-4 md:grid-cols-2 md:flex md:flex-wrap md:-mx-4">
                <div className="w-full md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="performaBillNo"
                  >
                    Performa Bill No:
                  </label>
                  <input
                    type="text"
                    id="performaBillNo"
                    placeholder="Performa Bill No"
                    value={performaBillNo ?? ""}
                    onChange={(e) => setPerformaBillNo(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="w-full md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="challanNo"
                  >
                    Challan No:
                  </label>
                  <input
                    type="text"
                    id="challanNo"
                    placeholder="Enter Challan No (Optional)"
                    value={challanNo ?? ""}
                    onChange={(e) => setChallanNo(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="w-full pt-8 md:w-1/3 md:px-4">
                  <label
                    className="block mb-2 font-bold text-gray-700"
                    htmlFor="commission"
                  >
                    Total Commission:
                  </label>
                  <input
                    type="text"
                    id="commission"
                    placeholder="Enter Total Commision (Optional)"
                    value={commission ?? ""}
                    onChange={(e) => setCommission(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
                  />
                </div>
              </div>
              <div className="flex justify-end my-4">
                <button
                  type="submit"
                  className="px-4 py-2 text-white bg-blue-900 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                >
                  {addBtnClicked && (
                    <FaSpinner className="mx-2 animate-spin" size={20} />
                  )}
                  {!addBtnClicked && "Submit Order"}
                </button>
              </div>
            </div>
          </form>
          {showModal && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75">
              <div className="p-4 bg-white rounded-lg">
                <div className="text-center">
                  <img
                    src={
                      upiPaymentMethod === "googlePay"
                        ? "/assets/google-pay-qr-code.png"
                        : "/assets/phone-pay-qr-code.png"
                    }
                    alt={
                      upiPaymentMethod === "googlePay"
                        ? "Google Pay QR Code"
                        : "Phone Pay QR Code"
                    }
                    className="mx-auto h-72 md:h-96"
                  />
                  <p className="mt-2">Scan the QR code to make payment</p>
                  <button
                    onClick={handleCloseModal}
                    className="px-4 py-2 mt-4 font-semibold text-white bg-blue-900 rounded-lg hover:bg-blue-700"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </AdminLayout>
    </ProtectedRoute>
  );
}
