import React, { useContext, useState } from "react";
import { RxDashboard } from "react-icons/rx";
import { FaSpinner, FaUserTie } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { AuthContext } from "../utils/authContext";
import { Link, useLocation } from "react-router-dom";
import { AiFillMessage } from "react-icons/ai";
import { HiUsers, HiUser } from "react-icons/hi";
import { FaPeopleArrows } from "react-icons/fa";
import { MdShoppingCart } from "react-icons/md";
import { RiBillLine } from "react-icons/ri";
import { TbRefresh } from "react-icons/tb";
import useOrdersStore from "../utils/useOrdersStore";

export default function AdminLayout({ children }) {
  const location = useLocation();
  const { signOut } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const { setLoading, setCached, fetchOrders } = useOrdersStore();

  const handleRefreshData = async () => {
    setLoading(true);
    setCached(false);
    await fetchOrders();
  };

  const handleLogOut = async () => {
    try {
      setIsLoading(true);
      await signOut();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const activeClassName = "px-2 py-2 mx-1 rounded-md bg-blue-950";
  const defaultClassName =
    "px-2 py-2 mx-1 bg-blue-900 rounded-md hover:bg-blue-950";

  return (
    <main className="flex min-h-screen fles-col bg-[#F6F7F9]">
      <div className="w-2/12 h-screen bg-blue-900 sidebar">
        <div className="flex-row items-center justify-between mt-4">
          <div className="flex items-center justify-center">
            <Link to="/admin/dashboard">
              <img
                height={64}
                width={64}
                src="/assets/logo.svg"
                alt="logo"
                className="w-10 h-10 lg:w-32 lg:h-32"
              />
            </Link>
          </div>
          <ul className="py-10 space-y-1 ">
            <li
              className={`${
                location.pathname === "/admin/dashboard"
                  ? activeClassName
                  : defaultClassName
              }`}
            >
              <Link
                to="/admin/dashboard"
                className="flex items-center space-x-2 text-white"
              >
                <RxDashboard />
                <h1 className="hidden font-semibold md:block">Dashboard</h1>
              </Link>
            </li>

            <li
              className={`${
                location.pathname === "/admin/advisors"
                  ? activeClassName
                  : defaultClassName
              }`}
            >
              <Link
                to="/admin/advisors"
                className="flex items-center space-x-2 text-white"
              >
                <HiUser />
                <h1 className="hidden font-semibold md:block">Advisors</h1>
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/admin/employees"
                  ? activeClassName
                  : defaultClassName
              }`}
            >
              {" "}
              <Link
                to="/admin/employees"
                className="flex items-center space-x-2 text-white"
              >
                <FaUserTie />
                <h1 className="hidden font-semibold md:block">Employees</h1>
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/admin/customers"
                  ? activeClassName
                  : defaultClassName
              }`}
            >
              <Link
                to="/admin/customers"
                className="flex items-center space-x-2 text-white"
              >
                <FaPeopleArrows />
                <h1 className="hidden font-semibold md:block">Customers</h1>
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/admin/orders"
                  ? activeClassName
                  : defaultClassName
              }`}
            >
              <Link
                to="/admin/orders"
                className="flex items-center space-x-2 text-white"
              >
                <MdShoppingCart />
                <h1 className="hidden font-semibold md:block">Orders</h1>
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/admin/billing"
                  ? activeClassName
                  : defaultClassName
              }`}
            >
              <Link
                to="/admin/billing"
                className="flex items-center space-x-2 text-white"
              >
                <RiBillLine />
                <h1 className="hidden font-semibold md:block">Billing</h1>
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/admin/messaging"
                  ? activeClassName
                  : defaultClassName
              }`}
            >
              <Link
                to="/admin/messaging"
                className="flex items-center space-x-2 text-white"
              >
                <AiFillMessage />
                <h1 className="hidden font-semibold md:block">Messaging</h1>
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/admin/users"
                  ? activeClassName
                  : defaultClassName
              }`}
            >
              <Link
                to="/admin/users"
                className="flex items-center space-x-2 text-white"
              >
                <HiUsers />
                <h1 className="hidden font-semibold md:block">Users</h1>
              </Link>
            </li>
          </ul>
        </div>
        <div className="fixed flex-col mx-1 bottom-4">
          <div className="flex flex-col ">
            <button
              onClick={handleRefreshData}
              className="px-2 py-2 bg-blue-900 rounded-md hover:bg-blue-950"
            >
              <div className="flex items-center space-x-2 text-white ">
                <TbRefresh />
                <h1 className="hidden font-semibold md:block">Refresh Data</h1>
              </div>
            </button>
            <button
              onClick={handleLogOut}
              className="px-2 py-2 bg-blue-900 rounded-md hover:bg-blue-950"
            >
              {isLoading ? (
                <FaSpinner className="mx-2 animate-spin" size={20} />
              ) : (
                <div className="flex items-center space-x-2 text-white ">
                  <FiLogOut />
                  <h1 className="hidden font-semibold md:block">Log Out </h1>
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="w-10/12 h-screen main ">{children}</div>
    </main>
  );
}
